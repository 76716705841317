<template lang="">
  <div
    class="d-flex-center gap-3 py-50 position-sticky w-100 position-bottom-0 rounded-top px-3 position-left-50 bg-blur"
    :style="`${isMobileView ? 'bottom: 15px' : ''}`"
  >
    <BButton
      variant="secondary"
      pill
      :class="`rounded-lg ${isMobileView ? 'text-14px' : 'text-18px'} fw-600 py-75 px-2`"
      @click="$router.go(-1)"
    >
      Quay lại
    </BButton>
    <BButton
      variant="success"
      pill
      :class="`rounded-lg ${isMobileView ? 'text-14px' : 'text-18px'} fw-600 py-75 px-2`"
      @click="onBookHandle"
    >
      Đặt Tour
    </BButton>
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    tourSelected: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(_, { emit }) {
    function onBookHandle() {
      emit('bookTour')
    }
    return {
      onBookHandle,
    }
  },
}
</script>
<style lang="scss" scoped>
.bg-blur {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
  }
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    staticClass: "rounded-8px mb-0 h-100"
  }, [_c('Stack', {
    staticClass: "gap-2"
  }, [_c('HStack', {
    attrs: {
      "between": ""
    }
  }, [_c('div'), _c('div', {
    class: "d-flex-center text-tour ".concat(_vm.isMobileView ? 'text-16px' : 'text-24px', " fw-700 flex-1")
  }, [_vm._v(" Chi tiết giá ")]), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.window",
      value: {
        title: _vm.isShowCommission ? _vm.$t('tour.showCommission') : _vm.$t('tour.hideCommission')
      },
      expression: "{ title: isShowCommission ? $t('tour.showCommission') : $t('tour.hideCommission') }",
      modifiers: {
        "hover": true,
        "window": true
      }
    }],
    staticClass: "p-50 rounded-circle",
    attrs: {
      "variant": "flat-success"
    },
    on: {
      "click": _vm.toggleShowCommission
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": _vm.isShowCommission ? 'moneyOutline' : 'notMoneyOutline',
      "size": "18"
    }
  })], 1)], 1), _c('BTable', {
    attrs: {
      "items": _vm.priceTableData,
      "fields": _vm.tableColumns,
      "responsive": "",
      "primary-key": "id",
      "show-empty": "",
      "hover": "",
      "empty-text": _vm.$t('noMatchingResult')
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: column.label,
            staticClass: "text-dark text-nowrap d-flex-center"
          }, [_vm._v(" " + _vm._s(_vm.$t("tour.field.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(type)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "fw-700 text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.$t("tour.passenger.".concat(item.type))) + " ")]), _c('span', {
          staticClass: "text-12px text-nowrap",
          staticStyle: {
            "font-weight": "400"
          }
        }, [item.ageFrom && item.ageTo ? _c('div', [_vm._v(" (" + _vm._s(_vm.$t('tour.passenger.ageBetween', {
          ageFrom: item.ageFrom,
          ageTo: item.ageTo
        })) + ") ")]) : item.ageFrom ? _c('div', [_vm._v(" (" + _vm._s(_vm.$t('tour.passenger.ageFrom', {
          ageFrom: item.ageFrom
        })) + ") ")]) : item.ageTo ? _c('div', [_vm._v(" (" + _vm._s(_vm.$t('tour.passenger.ageTo', {
          ageTo: item.ageTo
        })) + ") ")]) : _vm._e()])];
      }
    }, {
      key: "cell(unitPrice)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "d-flex-center"
        }, [_c('div', {
          staticClass: "text-right fw-700",
          staticStyle: {
            "min-width": "100px"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.unitPrice)) + " ")])])];
      }
    }, {
      key: "cell(number)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('PassengerCounter', {
          attrs: {
            "number": _vm.numberPassengers["number".concat(item.type)]
          },
          on: {
            "changeNumber": function changeNumber(val) {
              return _vm.$emit('updateNumberPassengers', item.type, val);
            }
          }
        })];
      }
    }, {
      key: "cell(commission)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex-center"
        }, [_c('div', {
          staticClass: "text-right fw-700",
          staticStyle: {
            "min-width": "100px"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.commission * _vm.numberPassengers["number".concat(item.type)])) + " ")])])];
      }
    }, {
      key: "cell(totalAmount)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex-center"
        }, [_c('div', {
          staticClass: "text-right fw-700",
          staticStyle: {
            "min-width": "100px"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.unitPrice * _vm.numberPassengers["number".concat(item.type)])) + " ")])])];
      }
    }], null, true)
  }), _c('HStack', {
    attrs: {
      "between": ""
    }
  }, [_c('div', [_vm.isShowCommission ? _c('HStack', {
    attrs: {
      "center": ""
    }
  }, [_c('div', {
    staticClass: "fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('tour.field.totalCommission')) + ": ")]), _c('div', {
    staticClass: "fw-700 text-success text-20px"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceTableData.reduce(function (total, item) {
    return total + item.commission * _vm.numberPassengers["number".concat(item.type)];
  }, 0))) + " ")])]) : _vm._e()], 1), _c('div', {
    class: "fit-content d-flex-center gap-2 ".concat(_vm.isMobileView ? 'mx-2' : 'mx-4')
  }, [_c('div', {
    staticClass: "fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('tour.totalPrice')) + ": ")]), _c('div', {
    class: "fw-800 text-danger ".concat(_vm.isMobileView ? 'text-20px' : 'text-24px')
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceTableData.reduce(function (total, item) {
    return total + item.unitPrice * _vm.numberPassengers["number".concat(item.type)];
  }, 0))) + " ")])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="">
  <BCard class="rounded-8px mb-0 h-100">
    <Stack class="gap-2">
      <HStack between>
        <div />
        <div :class="`d-flex-center text-tour ${isMobileView ? 'text-16px' : 'text-24px'} fw-700 flex-1`">
          Chi tiết giá
        </div>
        <BButton
          v-b-tooltip.hover.window="{ title: isShowCommission ? $t('tour.showCommission') : $t('tour.hideCommission') }"
          variant="flat-success"
          class="p-50 rounded-circle"
          @click="toggleShowCommission"
        >
          <IAmIcon
            :icon="isShowCommission ? 'moneyOutline' : 'notMoneyOutline'"
            size="18"
          />
        </BButton>
      </HStack>

      <BTable
        :items="priceTableData"
        :fields="tableColumns"
        responsive
        primary-key="id"
        show-empty
        hover
        :empty-text="$t('noMatchingResult')"
      >
        <template
          v-for="column in tableColumns"
          #[`head(${column.key})`]="data"
        >
          <div
            :key="column.label"
            class="text-dark text-nowrap d-flex-center"
          >
            {{ $t(`tour.field.${data.label}`) }}
          </div>
        </template>

        <template #cell(type)="{ item }">
          <div class="fw-700 text-nowrap">
            {{ $t(`tour.passenger.${item.type}`) }}
          </div>
          <span
            style="font-weight: 400"
            class="text-12px text-nowrap"
          >
            <div v-if="item.ageFrom && item.ageTo">
              ({{ $t('tour.passenger.ageBetween', { ageFrom: item.ageFrom, ageTo: item.ageTo }) }})
            </div>
            <div v-else-if="item.ageFrom">
              ({{ $t('tour.passenger.ageFrom', { ageFrom: item.ageFrom }) }})
            </div>
            <div v-else-if="item.ageTo">
              ({{ $t('tour.passenger.ageTo', { ageTo: item.ageTo }) }})
            </div>
          </span>
        </template>

        <template #cell(unitPrice)="{ item }">
          <div class="d-flex-center">
            <div
              style="min-width: 100px"
              class="text-right fw-700"
            >
              {{ formatCurrency(item.unitPrice) }}
            </div>
          </div>
        </template>

        <template #cell(number)="{ item }">
          <PassengerCounter
            :number="numberPassengers[`number${item.type}`]"
            @changeNumber="val => $emit('updateNumberPassengers', item.type, val)"
          />
        </template>

        <template #cell(commission)="{ item }">
          <div class="d-flex-center">
            <div
              style="min-width: 100px"
              class="text-right fw-700"
            >
              {{ formatCurrency(item.commission * numberPassengers[`number${item.type}`]) }}
            </div>
          </div>
        </template>

        <template #cell(totalAmount)="{ item }">
          <div class="d-flex-center">
            <div
              style="min-width: 100px"
              class="text-right fw-700"
            >
              {{ formatCurrency(item.unitPrice * numberPassengers[`number${item.type}`]) }}
            </div>
          </div>
        </template>
      </BTable>

      <HStack between>
        <div>
          <HStack
            v-if="isShowCommission"
            center
          >
            <div class="fw-700">
              {{ $t('tour.field.totalCommission') }}:
            </div>
            <div class="fw-700 text-success text-20px">
              {{ formatCurrency(priceTableData.reduce((total, item) => total + item.commission * numberPassengers[`number${item.type}`], 0)) }}
            </div>
          </HStack>
        </div>
        <div :class="`fit-content d-flex-center gap-2 ${isMobileView ? 'mx-2' : 'mx-4'}`">
          <div class="fw-700">
            {{ $t('tour.totalPrice') }}:
          </div>
          <div :class="`fw-800 text-danger ${isMobileView ? 'text-20px' : 'text-24px'}`">
            {{ formatCurrency(priceTableData.reduce((total, item) => total + item.unitPrice * numberPassengers[`number${item.type}`], 0)) }}
          </div>
        </div>
      </HStack>
    </Stack>
  </BCard>
</template>
<script>
import { computed, ref } from '@vue/composition-api'
import { BButton, BCard, BTable } from 'bootstrap-vue'

import { formatCurrency } from '@/@core/utils/filter'
import store from '@/store'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    PassengerCounter: () => import('../components/PassengerCounter.vue'),
  },
  props: {
    tourSelected: { type: Object, default: () => ({}) },
    numberPassengers: { type: Object, default: () => ({}) },
  },
  setup(props) {
    const {
      tourSelected,
    } = props
    const {
      adultsPriceSales, childrenHasBedPriceSales, childrenNoBedPriceSales, infantPriceSales,
      adultsAgencyPromotion, children1AgencyPromotion, children2AgencyPromotion, infantAgencyPromotion,
      agesAdults, agesChildren1From, agesChildren1To, agesChildren2From, agesChildren2To, agesInfant,
    } = tourSelected
    const priceTableData = computed(() => [
      {
        type: 'Adults',
        ageFrom: agesAdults,
        unitPrice: adultsPriceSales,
        commission: adultsAgencyPromotion ?? 0,
      },
      {
        type: 'Children1',
        ageFrom: agesChildren1From,
        ageTo: agesChildren1To,
        unitPrice: childrenHasBedPriceSales,
        commission: children1AgencyPromotion ?? 0,
      },
      {
        type: 'Children2',
        ageFrom: agesChildren2From || agesChildren1From,
        ageTo: agesChildren2To || agesChildren1To,
        unitPrice: childrenNoBedPriceSales,
        commission: children2AgencyPromotion ?? 0,
      },
      {
        type: 'Infant',
        ageTo: agesInfant,
        unitPrice: infantPriceSales,
        commission: infantAgencyPromotion ?? 0,
      },
    ])

    const tableColumnsRaw = ref([
      { key: 'type', label: 'type' },
      { key: 'unitPrice', label: 'unitPrice' },
      { key: 'number', label: 'number' },
      { key: 'commission', label: 'commission' },
      { key: 'totalAmount', label: 'totalAmount' },
    ])

    const isShowCommission = computed(() => store.getters['app-tour/getShowCommission'])

    function toggleShowCommission() {
      store.dispatch('app-tour/setShowCommission', !isShowCommission.value)
    }

    const tableColumns = computed(() => isShowCommission.value
      ? tableColumnsRaw.value
      : tableColumnsRaw.value.filter(col => col.key !== 'commission'))

    return {
      priceTableData,
      tableColumns,
      formatCurrency,
      isShowCommission,
      toggleShowCommission,
    }
  },
}
</script>
<style lang="scss" scoped>
</style>

import store from '@/store'

import useToast from '@useToast'

import tourStoreModule from '../tourStoreModule'

export default function useTourBookHandle() {
  if (!store.hasModule('app-tour')) {
    store.registerModule('app-tour', tourStoreModule) // NOTE: register store khi import file js này, đến hiện tại thì vẫn oke
  }
  const { toastError } = useToast()

  const checkSeatAvailable = priceId => new Promise((resolve, reject) => {
    store
      .dispatch('app-tour/checkSeatAvailable', priceId)
      .then(res => {
        resolve(res.data)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        reject(e)
      })
  })

  const createTourBooking = payload => new Promise((resolve, reject) => {
    store
      .dispatch('app-tour/createTourBooking', payload)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        reject(e)
      })
  })

  return {
    checkSeatAvailable, createTourBooking,
  }
}

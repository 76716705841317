<template lang="">
  <IAmOverlay :loading="loadingDetail">
    <div class="position-relative">
      <ValidationObserver ref="refFormObserver">
        <div
          v-if="tourSelected"
          class="d-flex flex-column gap-3 my-1"
        >
          <TourDetailBreadcrumbs :paths="paths" />
          <TourDetailScheduleAndPrice
            :tourSelected="tourSelected"
            :showInfo="true"
            :title="'Tóm tắt chuyến đi'"
          />
          <TourBookContactInfo
            :contactName.sync="bookingTourData.contactName"
            :contactPhone.sync="bookingTourData.contactPhone"
            :email.sync="bookingTourData.email"
            :address.sync="bookingTourData.address"
            :customerId.sync="bookingTourData.customerId"
          />
          <!-- :sendMailCustomer.sync="bookingTourData.sendMailCustomer" -->
          <TourBookPriceDetail
            :tourSelected="tourSelected"
            :numberPassengers="{
              numberAdults: bookingTourData.numberAdults,
              numberChildren1: bookingTourData.numberChildren1,
              numberChildren2: bookingTourData.numberChildren2,
              numberInfant: bookingTourData.numberInfant,
            }"
            @updateNumberPassengers="updateNumberPassengers"
          />
          <TourBookFooter @bookTour="onBookTourHandle" />

          <TourBookSuccessfulModal :bookingCode="bookingCode" />
        </div>

        <BAlert
          v-else
          show
          variant="danger"
          class="px-2 py-1"
        >
          Không tìm thấy tour đã chọn. Vui lòng chọn lại tour!
        </BAlert>
      </ValidationObserver>
    </div>
  </IAmOverlay>
</template>
<script>
import { BAlert } from 'bootstrap-vue'
import Vue from 'vue'
import { ValidationObserver } from 'vee-validate'
import { computed, ref } from '@vue/composition-api'

import formValidation from '@/@core/comp-functions/forms/form-validation'
import store from '@/store'

import useToast from '@useToast'

import TourDetailScheduleAndPrice from '../components/TourDetailScheduleAndPrice.vue'
import TourBookContactInfo from './TourBookContactInfo.vue'
import TourBookPriceDetail from './TourBookPriceDetail.vue'
import TourBookFooter from './TourBookFooter.vue'
import useTourBookHandle from './useTourBookHandle'
import useTourDetailHandle from '../detail/useTourDetailHandle'
import TourDetailBreadcrumbs from '../components/TourDetailBreadcrumbs.vue'

export default {
  components: {
    BAlert,
    TourDetailScheduleAndPrice,
    TourBookContactInfo,
    TourBookPriceDetail,
    TourBookFooter,
    ValidationObserver,
    TourDetailBreadcrumbs,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    TourBookSuccessfulModal: () => import('./TourBookSuccessfulModal.vue'),
  },
  setup(props, { root }) {
    const { tourSelected, loadingDetail } = useTourDetailHandle()
    const { checkSeatAvailable, createTourBooking } = useTourBookHandle()

    const { toastSuccess, toastError } = useToast()

    const { refFormObserver } = formValidation()

    const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    const bookingTourData = ref({
      contactName: agencyData.value?.agencyName ?? '',
      contactPhone: agencyData.value?.agencyOwner?.phoneNumber ?? '',
      email: agencyData.value?.emailAddress ?? '',
      address: agencyData.value?.agencyAddress ?? '',
      numberAdults: 1,
      numberChildren1: 0,
      numberChildren2: 0,
      numberInfant: 0,
      sendMailCustomer: false,
      customerId: null,
    })

    // bookingCode để redirect to detail
    const bookingCode = ref(null)

    async function onBookTourHandle() {
      const valid = await refFormObserver.value.validate()
      if (!valid) return
      // check seat available
      this.$bvModal.show('modal-api-loading')
      const priceId = tourSelected.value.priceId
      const seatAvailable = await checkSeatAvailable(priceId)
      this.$bvModal.hide('modal-api-loading')

      // confirm book
      if (seatAvailable) {
        const isConfirm = await Vue.swal({
          title: 'Xác nhận đặt Tour',
          html: `
                  <div class="text-20px font-weight-bolder">
                    Bạn chắc chắn đặt Tour đã chọn?
                  </div>`,
          icon: 'question',
          showCancelButton: true,
          showConfirmButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: 'Đồng ý đặt Tour',
          cancelButtonText: 'Huỷ',
          customClass: {
            confirmButton: 'btn btn-success mx-50',
            cancelButton: 'btn btn-danger mx-50',
            title: 'text-20px font-weight-bolder text-success',
          },
          buttonsStyling: false,
        })
        if (isConfirm.isConfirmed) {
          this.$bvModal.show('modal-api-loading')
          bookingCode.value = null
          try {
            const payload = {
              type: tourSelected.value.type,
              promotionCode: tourSelected.value?.promotionCode ?? undefined,
              priceId,
              contactName: bookingTourData.value.contactName, // họ và tên liên lạc
              contactPhone: bookingTourData.value.contactPhone, // sđt
              email: bookingTourData.value.email, // email
              address: bookingTourData.value.address, // địa chỉ
              numberAdults: bookingTourData.value.numberAdults, // số lượng người lớn
              numberChildren1: bookingTourData.value.numberChildren1, // số lượng trẻ em loại 1 (Nếu quốc tế là trẻ em có up lên người lớn, nếu nội địa là trẻ em 6 - dưới 11 tuổi)
              numberChildren2: bookingTourData.value.numberChildren2, // số lượng trẻ em loại 2 (Nếu quốc tế là trẻ em, nếu nội địa là trẻ em 2 - dưới 5 tuổi)
              numberInfant: bookingTourData.value.numberInfant, // số lượng trẻ nhỏ
              customerId: bookingTourData.value?.customerId ?? undefined, // gán customer
              sendMailCustomer: bookingTourData.value.sendMailCustomer, // gửi mail cho khách hàng
              // totalSingleRoom: 0,
              // noteRoomSingle: '',
              // notesGhepTour: '',
              // noteInfoBooking: '',
              // noteInfoTax: '',
              // noteAccountingNotes: '',
            }

            const resBookTour = await createTourBooking(payload)
            toastSuccess('Đặt Tour thành công')
            bookingCode.value = resBookTour?.bookingCode ?? null
            root.$bvModal.show('modal-tour-book-successful')
          } catch (error) {
            toastError('Lỗi đặt Tour')
          } finally {
            this.$bvModal.hide('modal-api-loading')
          }
        }
      } else {
        Vue.swal({
          title: 'Hết chỗ',
          html: `
                  <div class="text-20px font-weight-bolder text-nowrap">
                    Tour đã hết chỗ. Vui lòng chọn tour khác!
                  </div>`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger',
            title: 'text-20px font-weight-bolder text-danger',
          },
        })
      }
    }

    function updateNumberPassengers(type, value) {
      bookingTourData.value[`number${type}`] = value
    }

    const paths = computed(() => [
      {
        name: 'Tour Du Lịch',
        to: { name: 'apps-toursList' },
      },
      {
        name: tourSelected.value?.name,
        to: { name: 'apps-toursDetail', params: { id: tourSelected.value?.priceId } },
      },
      {
        name: 'Đặt tour',
        current: true,
      },
    ])

    return {
      tourSelected,
      onBookTourHandle,
      bookingTourData,
      refFormObserver,
      updateNumberPassengers,
      loadingDetail,
      paths,
      bookingCode,
    }
  },
}
</script>
<style lang="">

</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex-center gap-3 py-50 position-sticky w-100 position-bottom-0 rounded-top px-3 position-left-50 bg-blur",
    style: "".concat(_vm.isMobileView ? 'bottom: 15px' : '')
  }, [_c('BButton', {
    class: "rounded-lg ".concat(_vm.isMobileView ? 'text-14px' : 'text-18px', " fw-600 py-75 px-2"),
    attrs: {
      "variant": "secondary",
      "pill": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" Quay lại ")]), _c('BButton', {
    class: "rounded-lg ".concat(_vm.isMobileView ? 'text-14px' : 'text-18px', " fw-600 py-75 px-2"),
    attrs: {
      "variant": "success",
      "pill": ""
    },
    on: {
      "click": _vm.onBookHandle
    }
  }, [_vm._v(" Đặt Tour ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }